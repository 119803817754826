import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import Image from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageContainer from '../components/pageContainer';
import { RichText } from 'prismic-reactjs';

const PressContainer = styled.div`
  margin: 24px -8px -8px;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 600px) {
    margin: 48px -16px -16px;
  }
`;

const PressItem = styled.div`
  padding: 8px;
  width: 100%;
  margin-top: 32px;

  @media (min-width: 600px) {
    padding: 16px;
    width: 50%;
  }

  @media (min-width: 900px) {
    width: 33.3333%;
  }
`;
const PressLogoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 50%;
`;
const PressLogoInnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 32px;
  right: 32px;
  bottom: 0;
  display: flex;
  align-items: center;
`;
const PressLogo = styled.div`
  width: 100%;
`;
const PressTitle = styled.h2`
  margin: 0;
  text-align: center;
`;
const PressText = styled.p`
  margin: 16px 0 0;
  text-align: center;
`;
const PressLinkContainer = styled.div`
  text-align: center;
`;

const PressLink = styled.a`
  border: 2px solid #064f71;
  border-radius: 4px;
  padding: 8px 24px;
  display: inline-block;
  margin-top: 16px;
  color: #064f71;
  text-decoration: none;

  @media (min-width: 1200px) {
    //margin-top: 32px;
    font-size: 1.2rem;
  }
  //&:hover {
  //  background: #064f71;
  //  color: #fff;
  //}
`;

const PressPage = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { id: { regex: "/https/" } }) {
          edges {
            node {
              publicURL
              id
            }
          }
        }
        prismic {
          allPress_pages {
            edges {
              node {
                title
                description
                press {
                  logo_image
                  logo_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                  press_subtext
                  press_title
                  press_url {
                    ... on PRISMIC__FileLink {
                      _linkType
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const {
        node: { title, description, press },
      } = data.prismic.allPress_pages.edges.slice(0, 1).pop();
      const fileEdges = data.allFile.edges;

      return (
        <Layout>
          <SEO
            title={title ? RichText.asText(title) : null}
            description={description ? RichText.asText(description) : null}
          />
          <PageContainer>
            <PressContainer>
              {press.map((pressItem, index) => {
                let url = pressItem.press_url.url;
                if (pressItem.press_url._linkType === 'Link.file') {
                  for (let i = 0; i < fileEdges.length; i++) {
                    const fileEdge = fileEdges[i];
                    if (fileEdge.node.id === pressItem.press_url.url) {
                      url = fileEdge.node.publicURL;
                      break;
                    }
                  }
                }
                return (
                  <PressItem key={index}>
                    <PressLogoContainer>
                      <PressLogoInnerContainer>
                        <PressLogo>
                          <Image
                            fluid={
                              pressItem.logo_imageSharp.childImageSharp.fluid
                            }
                          />
                        </PressLogo>
                      </PressLogoInnerContainer>
                    </PressLogoContainer>
                    {pressItem.press_title && (
                      <PressTitle>
                        {RichText.asText(pressItem.press_title)}
                      </PressTitle>
                    )}
                    {pressItem.press_subtext && (
                      <PressText>
                        {RichText.asText(pressItem.press_subtext)}
                      </PressText>
                    )}
                    <PressLinkContainer>
                      <PressLink
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Read
                      </PressLink>
                    </PressLinkContainer>
                  </PressItem>
                );
              })}
            </PressContainer>
          </PageContainer>
        </Layout>
      );
    }}
  />
);

export default PressPage;
